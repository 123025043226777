require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

import "controllers";
import 'bootstrap/dist/js/bootstrap';
import "@fortawesome/fontawesome-free/js/all";
import "../stylesheets/application";

require("trix")
require("@rails/actiontext")

require("select2")
require("select2/dist/css/select2.css")
import "packs/select2";