// Usage: select2.org/tagging
document.addEventListener("turbolinks:load", () => {
  $('.js-tag-select').select2({
      tags: true,
      tokenSeparators: [','],
      maximumSelectionLength: 10,
      matcher: matchCustom,
  })
})

function matchCustom(params, data) {
  const removeDiacriticsAndTrim = (string) => {
      // separates letters from their diacritics and removes the diacritics
      return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim();
  }
  const normalizedContains = (data, term) => {
      const regExp = new RegExp(removeDiacriticsAndTrim(term), 'gi');
      return regExp.test(removeDiacriticsAndTrim(data))
  }

  // If there are no search terms, return all of the data
  if ($.trim(params.term) === '') {
    return data;
  }

  // Do not display the item if there is no 'text' property
  if (typeof data.text === 'undefined') {
    return null;
  }

  // `params.term` should be the term that is used for searching
  // `data.text` is the text that is displayed for the data object
  if (normalizedContains(data.text, params.term)) {
    return data;
  }

  // Return `null` if the term should not be displayed
  return null;
}